<template>
  <component :is="tag" v-if="nearbyFacilities.length" class="facilities-nearby">
    <div class="section-inner">
      <h2 v-if="blok.title" class="size-xl align-center margin-s-bottom">{{ blok.title }}</h2>
      <div
        v-if="
          isDesktop &&
          nearbyFacilities.length > 0 &&
          nearbyFacilities.length < 4
        "
        class="facility-cards"
      >
        <StoryblokComponent
          v-for="nearbyFacility in nearbyFacilities"
          :key="nearbyFacility._uid"
          :blok="nearbyFacility"
          :level="3"
          class="card"
          :size-hint="45"
        />
      </div>
      <div
        v-if="!isDesktop || nearbyFacilities.length > 3"
        class="carousel-container"
      >
        <GenericCarousel
          v-if="nearbyFacilities.length > 1"
          :items="nearbyFacilities"
          :slides-per-view="'auto'"
          :space-between="14"
          class="carousel-navigation-bottom"
          :child-level="3"
          child-classes="card"
          :size-hint="45"
        />
      </div>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  context: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
  sizeHint: {
    type: Number,
    required: false,
    default: 100,
  },
});

const { blok, context } = toRefs(props);
const mergedData = computed(() => ({ ...context.value, ...blok.value }));
const nearbyFacilities = mergedData.value?.nearbyFacilities?.length
  ? useFacilitiesMediaTextCta(mergedData.value?.nearbyFacilities)
  : [];

const isDesktop = inject("isDesktop");
</script>

<style lang="scss">
.background-facilities-nearby.alternate {
  @include pair-1;
  .swiper-button-prev,
  .swiper-button-next {
    &::before {
      background-color: var(--solid-01);
    }
  }
}
</style>

<style lang="scss" scoped>
.facility {
  &-cards {
    display: flex;
    align-items: center;
    gap: 0.83rem;
  }
}
</style>
